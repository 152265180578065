import React from "react"

const Summary = () => (
  <div className="flex pb-8">
    <div className="w-1/2 pr-4 lg:pr-12 border-r border-line">
      <h5 className="font-header font-semibold text-front text-sm uppercase">
        Company
      </h5>
      <h3 className="font-header font-light text-2xl text-front leading-tight">
        TouristMobile
      </h3>
    </div>
    <div className="w-1/2 pl-4 lg:pl-12">
      <h5 className="font-header font-semibold text-front text-sm uppercase">
        Focused on
      </h5>
      <div className="font-header font-light text-2xl text-front leading-tight">
        Writing my own blog
      </div>
    </div>
  </div>
)

export default Summary
