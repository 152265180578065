import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Summary from "../components/summary"
import Skills from "../components/skills/skills"
import Tools from "../components/tools/tools"
import Projects from "../components/projects/projects"
import WorkHistory from "../components/work-history/work-history"
import About from "../components/about/about"
import CustomFonts from "../components/custom-fonts/custom-fonts"

class AboutPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    let profile = {
      skills: ['TypeScript', 'Java', 'MongoDB / SQL'],
      tools: ['Angular', 'Node', 'Spring', 'Docker'],
      about: "Freelancer, Engineer and Enterpreneur who loves building great things and solving problems Got interested?"
    };
    let projects = [
      {
        "name": "Timeflies",
        "url": "https://timeflies.io",
        "description": "Time tracking system for small and medium companies",
        "status": "in progress",
        "tags": [
          "TypeScript",
          "Node",
          "Angular"
        ],
        "icon": "website",
        "image": null
      },
      {
        "name": "Tracords",
        "url": "https://tracords.com",
        "description": "Tracking passengers using a mobile application and analyzing the data via a web ui",
        "status": "in progress",
        "tags": [
          "TypeScript",
          "Node",
          "Angular",
          "Ionic"
        ],
        "icon": "website",
        "image": null
      }
    ];
    let history = [
      {
        company: "TouristMobile GmbH",
        position: "Full Stack Developer",
        period: "November 2016 - present",
        url: "https://tourist-mobile.at"
      }, {
        company: "Freelancing",
        position: "",
        period: "July 2015 - present",
        url: "https://matthiaslexer.com/about"
      }
    ];

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About" />
        <CustomFonts />
        <Summary />

        <div className="flex flex-wrap">
        <div className="md:w-3/4 pb-12 md:pr-8 lg:pr-12 xl:pr-20">
          {profile.skills && <Skills skills={profile.skills} />}
        </div>
        <div className="md:w-1/4 pb-12">
          {profile.tools && <Tools tools={profile.tools} />}
        </div>
      </div>

      {profile.about && <About about={profile.about} />}
      <Projects projects={projects} />
      <WorkHistory history={history} />

        <div className="flex flex-wrap">
      </div>
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
